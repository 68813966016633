//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBussMcv5HjUXI_J2Ht1Cu2xzY5SGlwbwM",
  authDomain: "telegram-mini-app-ba9b7.firebaseapp.com",
  databaseURL: "https://telegram-mini-app-ba9b7-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "telegram-mini-app-ba9b7",
  storageBucket: "telegram-mini-app-ba9b7.appspot.com",
  messagingSenderId: "334435957090",
  appId: "1:334435957090:web:c7bad12a1b8013cd92d43b",
  measurementId: "G-9XW4JMJDGN"
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
